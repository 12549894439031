<template>
	<a-spin :spinning="loading">
		<a-select v-model:value="selectId" @change="onChange" :disabled="disabled" :placeholder="placeholder" style="min-width: 200px;">
			<a-select-option :value="'SF'">顺丰</a-select-option>
			<a-select-option :value="'HTKY'">百世</a-select-option>
			<a-select-option :value="'ZTO'">中通</a-select-option>
			<a-select-option :value="'STO'">申通</a-select-option>
			<a-select-option :value="'YTO'">圆通</a-select-option>
			<a-select-option :value="'YD'">韵达</a-select-option>
			<a-select-option :value="'YZPY'">邮政</a-select-option>
			<a-select-option :value="'EMS'">EMS</a-select-option>
			<a-select-option :value="'HHTT'">天天</a-select-option>
			<a-select-option :value="'UC'">优速</a-select-option>
			<a-select-option :value="'DBL'">德邦</a-select-option>
			<a-select-option :value="'JD'">京东</a-select-option>
			<a-select-option :value="'SNWL'">苏宁</a-select-option>
			<a-select-option :value="'SX'">顺心捷达</a-select-option>
			<a-select-option :value="'JTSD'">极兔速递</a-select-option>
		</a-select>
	</a-spin>
</template>

<script>
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: [String, undefined],
				default: undefined
			},
			placeholder: {
				type: String,
				default: '请选择'
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			value(newVal) {
				this.selectId = newVal;
			}
		},
		data() {
			return {
				loading: false,
				selectId: undefined,
				list: [],
			}
		},
		methods: {
			onChange(e) {
				this.$emit('update:value', e);
			}
		}
	}
</script>

<style>
</style>